const products = [
    {
        productTitle: 'Кристалл',
        productText: `
        13 цифровых модулей, образующих
        Измерение трёхмерных сферических координат, высоты и скорости
        Сферический электронный адаптивный обзор пространства с высокой скоростью
        Многолучевой приём и обработка сигналовы
        Распознавание БЛА по винтовому эффекту
        Отсутствие вращающихся элементовы
        Малый вес и габариты
        Низкая стоимость
        `,
        productImgUrl: './image/crystal.png',
        productDocUrl: './docs/kristallDocs.jpeg'
    },
    {
        productTitle: 'Глобус',
        productText: `
        Максимальная дальности обнаружения: БпЛА «Maviс» - 6 км; крылатая ракета - 10 км; вертолёт - 30 км; самолёт - 40 км
        Зона обнаружения, сопровождения, целеуказания: 0 0 0 по азимуту - 0...360 ; по углу места -90 ...+90 ; по высоте - 0...5000 м; по дальности - 50...40000 м; по скорости воздушных целей - 1...300 м/с
        Точность целеуказания: 0 0 по азимуту -1 ; по углу места -1 ; по дальности -10 м; по скорости - 1 м/с
        время обзора - 3 секунды
        распознавание целей с использованием искусственного интеллекта и винтового эффекта
        диапазон рабочих частот - L
        интерфейс управления - Ehternet
        электропитание - 220/48 В, 700 Вт
        рабочий диапазон температур -40 +60
        габариты (высота*диаметр) - (0,6*0,8) м; вес - 65 кг
        `,
        productImgUrl: './image/globus.png',
        productDocUrl: './docs/globusDocs.pdf'
    },
    {
        productTitle: 'Фасет',
        productText: `
        48 цифровых модулей, образающих цилиндрическую поверхность
        Измерение трёхмерных сферических координат, высоты и скорости целей
        Круговой электронный адаптивный обзор пространства с высокой скоростью
        Многолучевой приём и обработка сигналов
        Распознавание БЛА по винтовому эффекту
        Отсутствие вращающихся элементов
        Малый вес и габариты
        Низкая стоимотсь
        `,
        productImgUrl: './image/faset.png',
        productDocUrl: './docs/fasetDocs.jpeg'
    },
    {
        productTitle: 'Завант 90',
        productText: `
        Размеры (Ш х В), м: 0,4 х 0, 3
        Частотный диапозон, МГц: L(1250)
        Полоса: МГц: ≤1.5
        Дальность обнаружения (тип объекта-автомобиль), км: 10
        Азимут, град: 100
        Угол наклона, град: от -5 до +50
        Скорость цели, км/ч: от 6
        Дальность, м: 25        
        `,
        productImgUrl: './image/zavant90.png',
        productDocUrl: './docs/zavant90Docs.jpeg'
    },
    {
        productTitle: 'Завант 180',
        productText: `
        Размеры (Ш х В), м: 0,4 х 0, 3
        Частотный диапозон, МГц: L(1250)
        Полоса: МГц: ≤1.5
        Дальность обнаружения (тип объекта-автомобиль), км: 10
        Азимут, град: 100
        Угол наклона, град: от -5 до +50
        Скорость цели, км/ч: от 6
        Дальность, м: 25
        `,
        productImgUrl: './image/zavant180.png',
        productDocUrl: './docs/zavant180Docs.jpeg'
    },
    {
        productTitle: 'Гарант 1',
        productText: `
        Режим функционирования: автоматический/полуавтоматический
        Дальность обнаружения радиоканалов управления, не менее м: 0…1500 (20000)
        Дальность подавления радиоканалов управления и навигации, не менее м: 0…1500 (20000)
        Пределы работы по азимуту, град: 0…360
        Пределы работы по углу места, град: 0…180
        Частотный диапазон, МГц: 2400…6000 (140…6000)
        Энергопотребление, Вт: 25
        Интерфейс управления: Ethernet (1000Base-T, 5GBase-T)
        Идентификация БЛА: По временной структуре радиосигналов и частотному диапазону
        Габариты, мм: 210*140*317
        `,
        productImgUrl: './image/garant1.png',
        productDocUrl: './docs/garant1Docs.pdf'
    },
    {
        productTitle: 'Блокировка Навигации',
        productText: `
        Режим функционирования: автоматический/полуавтоматический
        Дальность подавления навигации, не менее м: 0-1500
        Пределы работы по азимуту, град: 0-360
        Пределы работы по углу места, град: 0-180
        Частотный диапазон, МГц: 1560-1670
        Энергопотребление, Вт: 10
        Интерфейс управления: Ethernet (1000Base –T, 5GBase –T)
        `,
        productImgUrl: './image/radioNavigationLock.png',
        productDocUrl: './docs/radioNavigationLockDocs.pdf'
    }
]

const apps = [
    {
        productTitle: 'Программное Обеспечение Для Оптико-электронных Систем',
        productText: `
        1. нахождение летательных объектов
        2. детектирование
        3. сопровождение с помощью опорно-поворотного устройства
        Имеются решения как под Windows реализованные на c#, так и кроссплатформенные решения реализованные на c++
        `,
        productImgUrl: './image/optics.JPG',
        productDocUrl: ''
    },
    {
        productTitle: 'Программное Обеспечение Для Радиолокационных систем',
        productText: `
        Программное обеспечение для работы с радиолокаторами, написанное на c++ под Linux
        `,
        productImgUrl: './image/radar.JPG',
        productDocUrl: ''
    },
    {
        productTitle: 'Программное Обеспечение Для РБ Систем',
        productText: `
        Программа управления помехой GPS/GLONASS.
        Написана на языке C#.
        `,
        productImgUrl: './image/rb.png',
        productDocUrl: ''
    },
]

class Card {
    constructor(imgUrl, productName, cardId, wrappSelector) {
        this.imgUrl = imgUrl
        this.productName = productName
        this.wrappSelector = wrappSelector
        this.cardId = cardId
    }

    createCard() {
        const card = document.createElement('div')

        card.classList.add('development__card')
        card.classList.add('develop-card')
        card.style.background = `url('${this.imgUrl}') center center/cover no-repeat`
        
        card.innerHTML = `
        <div class="develop-card__descr">
            <h3 class="develop-card__title">${this.productName}</h3>
            
            <a href="./product.html" class="develop-card__link" id=card_${this.cardId}>
                Читать подробнее
            </a>
        </div>
        `

        return card
    }

    appendCard() {
        this.wrapp = document.querySelector(this.wrappSelector)
        this.wrapp.append(this.createCard())
    }

    get cardId() {
        return this._cardId
    }

    set cardId(id) {
        if (id <= 0)
            return 13
        return this._cardId = id
    }
}

class AppCard extends Card{
    createCard() {
        const app = document.createElement('a')

        app.classList.add('app-card')
        app.setAttribute('href', './product.html')
        app.setAttribute('id', `app_${this.cardId}`)

        app.innerHTML = `
        <div class="app-card__title">${this.productName}</div>
        `

        return app
    }
}

window.addEventListener('load', () => {
    try {
        const listWrapp = document.querySelector('.education__visible-wrapp'),
               activeClass = 'education__visible-wrapp_active',
               openBtn = document.querySelector('.education__subtitle')

        openBtn.addEventListener('click', () => {
            listWrapp.classList.toggle(activeClass)
        })
    } catch {
        for (let i = 0; i < apps.length; i++) {
            const app = new AppCard(apps[i].productImgUrl, apps[i].productTitle, i + 1, '.development__app-list')
            app.appendCard()
            
            const goToProductPage = document.querySelector(`#app_${app.cardId}`)
            
            goToProductPage.addEventListener('click', () => {
                localStorage.setItem('selectedTitle', apps[i].productTitle)
                localStorage.setItem('selectedText', apps[i].productText)
                localStorage.setItem('selectedImgUrl', apps[i].productImgUrl)
                localStorage.setItem('selectedDocs', apps[i].productDocUrl)
            })
        }
    
        for (let i = 0; i < products.length; i++) {
            const card = new Card(products[i].productImgUrl, products[i].productTitle, i + 1, '.development__list')
            card.appendCard()
            
            const goToProductPage = document.querySelector(`#card_${card.cardId}`)
            
            goToProductPage.addEventListener('click', () => {
                localStorage.setItem('selectedTitle', products[i].productTitle)
                localStorage.setItem('selectedText', products[i].productText)
                localStorage.setItem('selectedImgUrl', products[i].productImgUrl)
                localStorage.setItem('selectedDocs', products[i].productDocUrl)
            })
        }
    }
})
