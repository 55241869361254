window.addEventListener('load', () => {
    const presentTitle = document.querySelector('title').textContent

    try {
        if (document.querySelector('title').textContent != 'Разработки')
            document.querySelector('title').innerText = localStorage.selectedTitle

        document.querySelector('.product__title').innerText = localStorage.selectedTitle
        document.querySelector('.product__text').innerText = localStorage.selectedText
        document.querySelector('.product__image').setAttribute('src', localStorage.selectedImgUrl)

        if (localStorage.selectedDocs === '')
            document.querySelector('.product__link').style.display = 'none'
        else
            document.querySelector('.product__link').setAttribute('href', localStorage.selectedDocs)
    } catch {
        document.querySelector('title').innerText = presentTitle
    }
})
